import React, { useEffect, useState } from "react";
import BaseBottomSheet from "../../../global/components/drawers/BaseBottomSheet";
import useLineupStore from "../../lib/store/LineupStore";
import { getTeamById } from "../../../global/lib/utils/teams";
import {
  formationPositions,
  getFormationPosByAbbr,
} from "../../../global/lib/utils/formationPositions";

export default function LineupMoveSheet({
  currentPos,
  isEmg,
  listOrder,
  playerId,
}) {
  const [open, setOpen] = useState(false);
  const [
    players,
    bonusEligible,
    formation,
    dropPlayerIds,
    setDropPlayerIds,
    dropPositionAbbrs,
    setDropPositionAbbrs,
    selectedPlayerId,
    setSelectedPlayerId,
    setSelectedSwapPlayerId,
    setSelectedSwapPositionAbbr,
    setSelectedSwapPositionType,
    setSelectedSwapListOrder,
  ] = useLineupStore((state) => [
    state.players,
    state.bonusEligible,
    state.formation,
    state.dropPlayerIds,
    state.setDropPlayerIds,
    state.dropPositionAbbrs,
    state.setDropPositionAbbrs,
    state.selectedPlayerId,
    state.setSelectedPlayerId,
    state.setSelectedSwapPlayerId,
    state.setSelectedSwapPositionAbbr,
    state.setSelectedSwapPositionType,
    state.setSelectedSwapListOrder,
  ]);

  const player = players.find((p) => p.id === playerId);

  const isSelectedPlayer = player && selectedPlayerId === player.id;

  const isDropTarget =
    dropPositionAbbrs.includes(currentPos) ||
    dropPlayerIds.includes(player?.id);

  function onClick() {
    // setOpen(true);
    if (selectedPlayerId) {
      if (isSelectedPlayer) {
        setSelectedPlayerId(null);
        setDropPlayerIds([]);
        setDropPositionAbbrs([]);
      } else if (isDropTarget) {
        // Submit
        setSelectedSwapPositionAbbr(currentPos);
        setSelectedSwapPositionType(
          isEmg ? "emg" : currentPos === "BN" ? "bench" : "starter",
        );
        setSelectedSwapListOrder(listOrder);
        if (player) {
          setSelectedSwapPlayerId(player.id);
        }

        const lineupEvent = new CustomEvent("lineupEvent", {
          detail: { message: "Lineup is ready!", player: player },
        });
        document
          .querySelector('[data-controller="lineup"]')
          .dispatchEvent(lineupEvent);
      }
    } else {
      setSelectedPlayerId(player.id);
      let { swapStarters, swapEmgs } = getSwapPlayers(
        formation,
        player,
        players,
      );

      const dropPositionsAbbrs = formationPositions
        .filter((formationPos) => {
          return player.playerPos.some((p) =>
            formationPos.eligiblePlayerPos.includes(p),
          );
        })
        .map((formationPos) => formationPos.abbr);

      // console.log("Can play ", dropPositionsAbbrs);

      setDropPlayerIds(
        [...swapStarters, ...swapEmgs].map((player) => player.id),
      );
      setDropPositionAbbrs(dropPositionsAbbrs);
    }
  }

  const disabled =
    (!isDropTarget && selectedPlayerId && !isSelectedPlayer) ||
    (!playerId && !isDropTarget);

  return (
    <>
      <button
        onClick={onClick}
        data-droptarget={isDropTarget}
        disabled={disabled}
        className={`pointer-events-auto mr-0.5 w-12 transition-all h-8 -my-0.5 flex items-center justify-center  dark:text-white dark:border-gray-600 rounded-full font-semibold text-xxs uppercase ${disabled && selectedPlayerId ? "opacity-50" : ""} ${disabled ? "bg-none border text-gray-600 opacity-50" : isSelectedPlayer ? "bg-primary-600 text-white hover:bg-primary-800" : isDropTarget ? "bg-lime-600 hover:bg-lime-800 text-white" : "bg-primary-600 text-white hover:bg-primary-700"}`}
      >
        {currentPos}
      </button>

      {player ? (
        <BaseBottomSheet
          isOpen={open}
          setIsOpen={setOpen}
          title={<PlayerTitle player={player} />}
          content={<SwapList player={player} setIsOpen={setOpen} />}
          scrollable={false}
        />
      ) : (
        <BaseBottomSheet
          isOpen={open}
          setIsOpen={setOpen}
          title={<EmptyTitle currentPos={currentPos} />}
          content={<SwapList setIsOpen={setOpen} />}
          scrollable={false}
        />
      )}
    </>
  );
}

function PlayerTitle({ player }) {
  const team = getTeamById(player.teamId);

  return (
    <div className="sticky top-0 border-b dark:border-gray-700 py-2 px-4 text-center font-medium text-md items-center justify-center flex-col flex">
      <img
        className="my-1 h-6 w-6 rounded-full border border-gray-200 ring-1 ring-gray-400 md:ml-0.5 dark:border-gray-500 dark:ring-gray-400"
        src={`/build/images/sport/teams/logos/512/${team.id}.png`}
        alt={`${team.name} colours`}
      />
      <span className="mb-1 dark:text-white">{player.name}</span>
      <span className="font-light text-xs mb-1 dark:text-gray-200">
        Move {player.name} to the following slot
      </span>
    </div>
  );
}

function EmptyTitle({ currentPos }) {
  return (
    <div className="sticky top-0 border-b dark:border-gray-700 py-2 px-4 text-center font-medium text-md items-center justify-center flex-col flex">
      <span className="mb-1 dark:text-white">-- {currentPos} --</span>
      <span className="font-light text-xs mb-1 dark:text-gray-200">
        Move a player into this slot
      </span>
    </div>
  );
}

function getSwapPlayers(formation, player, players) {
  // Check starting positions
  const startingPositions = formation.starters;
  // console.log("starting positions", startingPositions);

  let swapStarters = [];
  let swapEmgs = [];

  // Check starters
  for (let i = 0; i < startingPositions.length; i++) {
    const startingAbbr = startingPositions[i].abbr;

    // console.log("checking formationPos", startingAbbr);

    if (startingAbbr === player.selectedPos && player.type === "starter") {
      // console.log("skipping, already playing ", startingAbbr);
      continue;
    }

    const startingFormationPos = getFormationPosByAbbr(startingAbbr);

    for (let j = 0; j < player.playerPos.length; j++) {
      const playerPos = player.playerPos[j];
      // console.log("is player pos eligible? ", playerPos);
      if (startingFormationPos.eligiblePlayerPos.includes(playerPos)) {
        // console.log("yes player can play");
        // positionsPlayerCanPlay.push(startingFormationPos);

        // Find other unlocked starters in this position as a potential swap target
        for (let k = 0; k < players.length; k++) {
          const swapPlayer = players[k];

          // console.log("checking", swapPlayer);
          if (swapPlayer.id === player.id) {
            // console.log("skipping self");
            continue;
          }

          if (swapPlayer.selectedPos !== startingAbbr) {
            // console.log("skipping, player not selected as " + startingAbbr);
            continue;
          }

          if (swapPlayer.type !== "starter") {
            // console.log("not a starter, skipping");
            continue;
          }

          // console.log("is swappable");
          if (!swapStarters.includes(swapPlayer)) {
            swapStarters.push(swapPlayer);
          }
        }
      } else {
        // console.log("not eligible");
      }
    }
  }

  // Check emg
  const emgPositions = formation.emgs;
  // console.log("emergency positions", emgPositions);

  for (let i = 0; i < emgPositions.length; i++) {
    const emgAbbr = emgPositions[i].abbr;

    // console.log("checking formationPos", emgAbbr);

    if (emgAbbr === player.selectedPos && player.type === "emg") {
      // console.log("skipping, already playing EMG ", emgAbbr);
      continue;
    }

    const emgFormationPos = getFormationPosByAbbr(emgAbbr);

    for (let j = 0; j < player.playerPos.length; j++) {
      const playerPos = player.playerPos[j];
      // console.log("is player pos eligible? ", playerPos);
      if (emgFormationPos.eligiblePlayerPos.includes(playerPos)) {
        // console.log("yes player can play");
        // positionsPlayerCanPlay.push(emgFormationPos);

        // Find other unlocked starters in this position as a potential swap target
        for (let k = 0; k < players.length; k++) {
          const swapPlayer = players[k];

          // console.log("checking", swapPlayer);
          if (swapPlayer.id === player.id) {
            // console.log("skipping self");
            continue;
          }

          if (swapPlayer.selectedPos !== emgAbbr) {
            // console.log("skipping, player not selected as " + emgAbbr);
            continue;
          }

          if (swapPlayer.type !== "emg") {
            // console.log("not an emg, skipping");
            continue;
          }

          // console.log("is swappable");
          if (!swapEmgs.includes(swapPlayer)) {
            swapEmgs.push(swapPlayer);
          }
        }
      } else {
        // console.log("not eligible");
      }
    }
  }

  return { swapStarters, swapEmgs };
}

function SwapList({ player, setIsOpen }) {
  const [players, bonusEligible, formation] = useLineupStore((state) => [
    state.players,
    state.bonusEligible,
    state.formation,
  ]);
  const handleCloseSheet = () => {
    setIsOpen(false);
  };

  // console.log("opened sheet", player);

  // let { swapStarters, swapEmgs } = getSwapPlayers(formation, player, players);

  // Listen for closeSheet events from the stimulus controller
  useEffect(() => {
    document.addEventListener("closeSheet", handleCloseSheet);

    return () => {
      document.removeEventListener("closeSheet", handleCloseSheet);
    };
  }, [setIsOpen]);

  return (
    <>
      <div className="pb-1 divide-y dark:divide-gray-700">
        {swapStarters.map((swapPlayer) => (
          <SwapOptionRow key={swapPlayer.id} player={swapPlayer} />
        ))}

        {swapEmgs.length > 0 && <div className="">Emergencies</div>}
        {swapEmgs.map((swapPlayer) => (
          <SwapOptionRow key={swapPlayer.id} player={swapPlayer} />
        ))}
        {/*<SwapOptionRow*/}
        {/*  player={{*/}
        {/*    id: 1,*/}
        {/*    name: "Max Gawn",*/}
        {/*    position: "RUC",*/}
        {/*    image: "/build/images/sport/teams/logos/512/11.png",*/}
        {/*  }}*/}
        {/*/>*/}
        {/*<SwapOptionRow*/}
        {/*  player={{*/}
        {/*    id: 2,*/}
        {/*    name: "Connor Rozee",*/}
        {/*    position: "MID",*/}
        {/*    image: "/build/images/sport/teams/logos/512/13.png",*/}
        {/*  }}*/}
        {/*/>*/}
        {/*<SwapOptionRow*/}
        {/*  player={{*/}
        {/*    id: 2,*/}
        {/*    name: "Zac Butters",*/}
        {/*    position: "MID",*/}
        {/*    image: "/build/images/sport/teams/logos/512/13.png",*/}
        {/*  }}*/}
        {/*/>*/}
        {/*<SwapOptionRow*/}
        {/*  player={{*/}
        {/*    id: 4,*/}
        {/*    name: "Jordan Dawson",*/}
        {/*    position: "MID",*/}
        {/*    image: "/build/images/sport/teams/logos/512/1.png",*/}
        {/*  }}*/}
        {/*/>*/}
        {/*<SwapOptionRow*/}
        {/*  player={{*/}
        {/*    id: 5,*/}
        {/*    name: "Harris Andrews",*/}
        {/*    position: "KEYD",*/}
        {/*    image: "/build/images/sport/teams/logos/512/2.png",*/}
        {/*  }}*/}
        {/*/>*/}
        {/*<SwapOptionRow*/}
        {/*  player={{*/}
        {/*    id: 6,*/}
        {/*    name: "Charlie Curnow",*/}
        {/*    position: "KEYF",*/}
        {/*    image: "/build/images/sport/teams/logos/512/3.png",*/}
        {/*  }}*/}
        {/*/>*/}
      </div>
    </>
  );
}

function SwapOptionRow({ onSubmit, player }) {
  const [loading, setLoading] = useState(false);

  const team = getTeamById(player.teamId);
  console.log(player, team);

  async function handleClick(event) {
    setLoading(true);

    const lineupEvent = new CustomEvent("lineupEvent", {
      detail: { message: "Lineup is ready!", player: player },
    });
    document
      .querySelector('[data-controller="lineup"]')
      .dispatchEvent(lineupEvent);
  }

  return (
    <button
      onClick={handleClick}
      className="flex cursor-pointer items-center w-full py-2 px-2"
    >
      <div className="relative">
        {loading && (
          <div
            role="status"
            className="absolute top-0 -my-0.5 ml-1.5 mr-3 inline-flex w-10 items-center justify-start text-left font-medium"
          >
            <svg
              aria-hidden="true"
              className="w-9 h-9 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        )}
        <div
          // type="submit"
          className="-my-0.5 ml-1.5 mr-3 inline-flex w-10 items-center justify-start text-left font-medium"
        >
          <div className="flex items-center justify-center h-9 w-9 text-gray-900 dark:text-blue-400 border-2 dark:border-gray-600 rounded-full text-xxs uppercase">
            <span>{player.selectedPos}</span>
          </div>
        </div>
      </div>
      <div className="inline-flex items-center justify-center pt-px text-left font-medium md:py-1 md:leading-tight">
        <img
          className="mr-2 h-7 w-7 rounded-full border border-gray-200 ring-1 ring-gray-400 md:ml-0.5 dark:border-gray-500 dark:ring-gray-400"
          src={`/build/images/sport/teams/logos/512/${team.id}.png`}
          alt={player.name}
        />
        <span className="inline-flex flex-col items-start justify-center leading-tight">
          <span className="inline-flex dark:text-white items-center justify-center text-md leading-tight">
            {player.name}
          </span>
          <span className="text-xs font-normal tracking-tight">
            {player.playerPos.join("/")}
          </span>
        </span>
      </div>
    </button>
  );
}
