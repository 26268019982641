export function getTeamById(teamId) {
  return teams.find((team) => team.id === teamId);
}

export const teams = [
  {
    id: 1,
    dataId: 10,
    name: "Adelaide Crows",
    city: "Adelaide",
    mascot: "Crows",
    abbr: "ADE",
  },
  {
    id: 2,
    dataId: 20,
    name: "Brisbane Lions",
    city: "Brisbane",
    mascot: "Lions",
    abbr: "BRL",
  },
  {
    id: 3,
    dataId: 30,
    name: "Carlton",
    city: "Carlton",
    mascot: "Blues",
    abbr: "CAR",
  },
  {
    id: 4,
    dataId: 40,
    name: "Collingwood",
    city: "Collingwood",
    mascot: "Magpies",
    abbr: "COL",
  },
  {
    id: 5,
    dataId: 50,
    name: "Essendon",
    city: "Essendon",
    mascot: "Bombers",
    abbr: "ESS",
  },
  {
    id: 6,
    dataId: 60,
    name: "Fremantle",
    city: "Fremantle",
    mascot: "Dockers",
    abbr: "FRE",
  },
  {
    id: 7,
    dataId: 1000,
    name: "Gold Coast Suns",
    city: "Gold Coast",
    mascot: "Suns",
    abbr: "GCS",
  },
  {
    id: 8,
    dataId: 70,
    name: "Geelong Cats",
    city: "Geelong",
    mascot: "Cats",
    abbr: "GEE",
  },
  {
    id: 9,
    dataId: 1010,
    name: "GWS Giants",
    city: "GWS",
    mascot: "Giants",
    abbr: "GWS",
  },
  {
    id: 10,
    dataId: 80,
    name: "Hawthorn",
    city: "Hawthorn",
    mascot: "Hawks",
    abbr: "HAW",
  },
  {
    id: 11,
    dataId: 90,
    name: "Melbourne",
    city: "Melbourne",
    mascot: "Demons",
    abbr: "MEL",
  },
  {
    id: 12,
    dataId: 100,
    name: "North Melbourne",
    city: "North Melbourne",
    mascot: "Kangaroos",
    abbr: "NTH",
  },
  {
    id: 13,
    dataId: 110,
    name: "Port Adelaide",
    city: "Port Adelaide",
    mascot: "Power",
    abbr: "PTA",
  },
  {
    id: 14,
    dataId: 120,
    name: "Richmond",
    city: "Richmond",
    mascot: "Tigers",
    abbr: "RIC",
  },
  {
    id: 15,
    dataId: 130,
    name: "St Kilda",
    city: "St Kilda",
    mascot: "Saints",
    abbr: "STK",
  },
  {
    id: 16,
    dataId: 160,
    name: "Sydney Swans",
    city: "Sydney",
    mascot: "Swans",
    abbr: "SYD",
  },
  {
    id: 17,
    dataId: 140,
    name: "Western Bulldogs",
    city: "Western",
    mascot: "Bulldogs",
    abbr: "WBD",
  },
  {
    id: 18,
    dataId: 150,
    name: "West Coast Eagles",
    city: "West Coast",
    mascot: "Eagles",
    abbr: "WCE",
  },
  {
    id: 19,
    dataId: 1900,
    name: "Tasmania Devils",
    city: "Tasmania",
    mascot: "Devis",
    abbr: "TA",
  },
  {
    id: 80,
    dataId: 8000,
    name: "Fitzroy Lions",
    city: "Fitzroy",
    mascot: "Lions",
    abbr: "FI",
  },
  {
    id: 81,
    dataId: 8100,
    name: "Brisbane Bears",
    city: "Brisbane",
    mascot: "Bears",
    abbr: "BB",
  },
  {
    id: 90,
    dataId: 9000,
    name: "University",
    city: "University",
    mascot: "Mugars",
    abbr: "UNI",
  },
];
